.contentWrapper {
    width: 100%;
    height: 85px;
    padding: 15px;
    position: sticky;
    top: 0;
    z-index: 4;
    background: linear-gradient(#9f9d9d, #d0cfcf);

    @media (min-width: 768px) {
        height: 50px;
        padding: 5px;
    }

    @media (min-width: 1024px) {
        padding-left: 10px;
        padding-right: 10px;
        height: 70px;
        background: linear-gradient(#000000, #000000cc);
    }

    .navigationLogin {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 5px;

        @media (min-width: 1024px) {
            padding: 10px 5px 10px 5px;
        }

        .btnBack {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .logoCar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 65%;

            .logoNav {
                height: 60px;
            }

            @media (min-width: 768px) {
                width: 30%;
            }

        }

        .titleNav {
            display: none;

            @media (min-width: 768px) {
                display: flex;
                flex-direction: row;
                font-size: 11px;
                align-items: center;

                .title {
                    color: var(--font-color);
                    margin-right: 10px;
                    text-decoration: none;
                }
            }

            @media (min-width: 1024px) {
                display: flex;
                flex-direction: row;
                font-size: 11px;
                align-items: center;

                .title {
                    color: var(--font-color);
                    margin-right: 20px;
                    text-decoration: none;
                }
            }

            @media (min-width: 1440px) {
                display: flex;
                flex-direction: row;
                font-size: 16px;
                font-weight: 200;
                align-items: center;

                .title {
                    color: var(--font-color);
                    margin-right: 30px;
                    text-decoration: none;
                }
            }
        }

        .accountContent {
            margin-right: 20px;
            position: relative;

            @media (min-width: 768px) {
                width: 10%;
            }

            @media (min-width: 1024px) {
                width: 100%;
                max-width: 115px;
            }

            .accountMenu {
                display: flex;
                flex-direction: row;
                background-color: #000000;
                opacity: 0.8;
                padding: 0px 12px 0px 12px;
                display: flex;
                justify-content: center;
                border-radius: 10px;
                margin-right: 15px;
                width: 100%;
                height: 100%;
                align-items: center;
                cursor: pointer;

                &:hover {
                    opacity: 0.9;
                }

                .iconUser {
                    margin-right: 5px;

                    @media (min-width: 1024px) {
                        font-size: 12px;
                    }
                }

                .accountTitle {
                    padding-left: 5px;
                    padding-right: 5px;
                    display: none;
                    color: var(--font-color);

                    &:hover {
                        text-decoration: none;
                    }

                    @media (min-width: 1024px) {
                        display: block;
                        font-size: 14px;
                    }
                }

                .iconArrow {
                    margin-left: 5px;
                }
            }

            .dropdownAccount {
                display: flex;
                flex-direction: column;
                position: absolute;
                background-color: #000000;
                opacity: 0.8;
                width: 100%;
                padding: 5px 8px 5px 8px;
                border-radius: 5px;

                .btnAcc {
                    color: var(--font-color);
                    font-weight: 400;
                    font-size: 14px;
                    padding-top: 10px;
                    opacity: 0.6;
                    cursor: pointer;
                    text-align: center;

                    &:last-child {
                        padding-bottom: 10px;
                    }

                    &:hover {
                        opacity: 0.9;
                        text-decoration: none;
                    }
                }
            }
        }

        .hambugerContent {
            background-color: #000000;
            opacity: 0.5;
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            @media (min-width: 768px) {
                display: none;
            }

            .hambugerMenu {
                display: inline-block;
                cursor: pointer;

                .barOne {
                    width: 30px;
                    height: 3px;
                    background-color: white;
                    margin: 6px 0;
                    transition: 0.4s;
                }

                .barTwo {
                    width: 30px;
                    height: 3px;
                    background-color: white;
                    margin: 6px 0;
                    transition: 0.4s;
                }

                .barThree {
                    width: 30px;
                    height: 3px;
                    background-color: white;
                    margin: 6px 0;
                    transition: 0.4s;
                }
            }

            .hambugerChange {

                .barOne {
                    width: 35px;
                    height: 5px;
                    background-color: #333;
                    margin: 6px 0;
                    transition: 0.4s;
                    transform: translate(0, 11px) rotate(-45deg);
                }

                .barTwo {
                    width: 35px;
                    height: 5px;
                    background-color: #333;
                    margin: 6px 0;
                    transition: 0.4s;
                    opacity: 0;
                }

                .barThree {
                    width: 35px;
                    height: 5px;
                    background-color: #333;
                    margin: 6px 0;
                    transition: 0.4s;
                    transform: translate(0, -11px) rotate(45deg);
                }
            }
        }


        .dropdown {
            display: none;
            cursor: pointer;

            @media (min-width: 768px) {
                display: block;
                border-radius: 8px;
                width: 75px;
                box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
                background-color: #000000;
            }

            .dropdownHeader {
                display: flex;
                flex-direction: row;
                padding-left: 12px;
                padding-right: 12px;
                justify-content: space-between;
                align-items: center;
                color: var(--font-color);

                @media (min-width: 1024px) {
                    height: 100%;
                    font-size: 14px;
                }
            }

            .dropdownBody {
                padding: 5px 8px 5px 8px;
                background-color: #000000;
                text-align: center;
                border-radius: 5px;

                .dropdownItem {
                    color: var(--font-color);
                    font-weight: 400;
                    font-size: 14px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 10px;
                    opacity: 0.6;

                    &:hover {
                        opacity: 1;
                    }

                    &:last-child {
                        padding-bottom: 10px;
                    }

                    .imgFlag {
                        padding-right: 5px;
                        width: 28px;
                        height: 19px;
                    }
                }

                .dropdownItem:hover {
                    cursor: pointer;
                }
            }

            .dropdownBodyopen {
                display: block;
            }
        }
    }

    .navigationGuest {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 5px;

        @media (max-width: 700px) {
            justify-content: space-between;
        }

        @media (min-width: 1024px) {
            padding: 10px 5px 10px 5px;
        }

        .btnBack {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .logoCar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 65%;

            @media (min-width: 768px) {
                margin-right: 20px;
            }

            a {
                @media (max-width: 768px) {
                    width: 100%;
                }

                .logoNav {
                    height: 100%;
                    width: 100%;
                }
            }

            @media (min-width: 768px) {
                width: 30%;
            }

        }

        .titleNav {
            display: none;

            @media (min-width: 768px) {
                display: flex;
                flex-direction: row;
                font-size: 11px;
                align-items: center;

                .title {
                    color: var(--font-color);
                    margin-right: 10px;
                    text-decoration: none;
                }
            }

            @media (min-width: 1024px) {
                display: flex;
                flex-direction: row;
                font-size: 11px;
                align-items: center;

                .title {
                    color: var(--font-color);
                    margin-right: 20px;
                    text-decoration: none;
                }
            }

            @media (min-width: 1440px) {
                display: flex;
                flex-direction: row;
                font-size: 16px;
                font-weight: 200;
                align-items: center;

                .title {
                    color: var(--font-color);
                    margin-right: 30px;
                    text-decoration: none;
                }
            }
        }

        .accountContent {
            margin-right: 20px;
            position: relative;

            @media (min-width: 768px) {
                width: 10%;
            }

            @media (min-width: 1024px) {
                width: 100%;
                max-width: 115px;
            }

            .accountMenu {
                display: flex;
                flex-direction: row;
                background-color: #000000;
                opacity: 0.8;
                padding: 0px 12px 0px 12px;
                display: flex;
                justify-content: center;
                border-radius: 10px;
                margin-right: 15px;
                width: 100%;
                height: 100%;
                align-items: center;
                cursor: pointer;

                &:hover {
                    opacity: 0.9;
                }

                .iconUser {
                    margin-right: 5px;

                    @media (min-width: 1024px) {
                        font-size: 12px;
                    }
                }

                .accountTitle {
                    padding-left: 5px;
                    padding-right: 5px;
                    display: none;
                    color: var(--font-color);

                    &:hover {
                        text-decoration: none;
                    }

                    @media (min-width: 1024px) {
                        display: block;
                        font-size: 14px;
                    }
                }

                .iconArrow {
                    margin-left: 5px;
                }
            }

            .dropdownAccount {
                display: flex;
                flex-direction: column;
                position: absolute;
                background-color: #000000;
                opacity: 0.8;
                width: 100%;
                padding: 5px 8px 5px 8px;
                border-radius: 5px;

                .btnAcc {
                    color: var(--font-color);
                    font-weight: 400;
                    font-size: 14px;
                    padding-top: 10px;
                    opacity: 0.6;
                    cursor: pointer;
                    text-align: center;

                    &:last-child {
                        padding-bottom: 10px;
                    }

                    &:hover {
                        opacity: 0.9;
                        text-decoration: none;
                    }
                }
            }
        }

        .hambugerContent {
            background-color: #000000;
            opacity: 0.5;
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            @media (min-width: 768px) {
                display: none;
            }

            .hambugerMenu {
                display: inline-block;
                cursor: pointer;

                .barOne {
                    width: 30px;
                    height: 3px;
                    background-color: white;
                    margin: 6px 0;
                    transition: 0.4s;
                }

                .barTwo {
                    width: 30px;
                    height: 3px;
                    background-color: white;
                    margin: 6px 0;
                    transition: 0.4s;
                }

                .barThree {
                    width: 30px;
                    height: 3px;
                    background-color: white;
                    margin: 6px 0;
                    transition: 0.4s;
                }
            }

            .hambugerChange {

                .barOne {
                    width: 35px;
                    height: 5px;
                    background-color: #333;
                    margin: 6px 0;
                    transition: 0.4s;
                    transform: translate(0, 11px) rotate(-45deg);
                }

                .barTwo {
                    width: 35px;
                    height: 5px;
                    background-color: #333;
                    margin: 6px 0;
                    transition: 0.4s;
                    opacity: 0;
                }

                .barThree {
                    width: 35px;
                    height: 5px;
                    background-color: #333;
                    margin: 6px 0;
                    transition: 0.4s;
                    transform: translate(0, -11px) rotate(45deg);
                }
            }
        }


        .dropdown {
            display: none;
            cursor: pointer;

            @media (min-width: 768px) {
                display: block;
                border-radius: 8px;
                width: 75px;
                box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
                background-color: #000000;
            }

            .dropdownHeader {
                display: flex;
                flex-direction: row;
                padding-left: 12px;
                padding-right: 12px;
                justify-content: space-between;
                align-items: center;
                color: var(--font-color);

                @media (min-width: 768px) {
                    font-size: 12px;
                    height: 100%;
                }

                @media (min-width: 1024px) {
                    height: 100%;
                    font-size: 14px;
                }
            }

            .dropdownBody {
                padding: 5px 8px 5px 8px;
                background-color: #000000;
                text-align: center;
                border-radius: 5px;

                .dropdownItem {
                    color: var(--font-color);
                    font-weight: 400;
                    font-size: 14px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 10px;
                    opacity: 0.6;

                    &:hover {
                        opacity: 1;
                    }

                    &:last-child {
                        padding-bottom: 10px;
                    }

                    .imgFlag {
                        padding-right: 5px;
                        width: 28px;
                        height: 19px;
                    }
                }

                .dropdownItem:hover {
                    cursor: pointer;
                }
            }

            .dropdownBodyopen {
                display: block;
            }
        }
    }

    .hideMenu {
        transition: all 0.5s ease-in-out;
        transform: translateX(100%);
        opacity: 1;
        position: fixed;
        z-index: 2;
        left: 0;
        top: 85px;
        width: 100%;
        height: 100vh;
        background-color: #7a5e1b;

        @media (min-width: 768px) {
            display: none;
        }

        .selectOptionsAccount {
            padding-left: 10px;
            padding-right: 10px;
            color: var(--font-color);
            display: flex;
            flex-direction: column;

            .btnAcc {
                color: white;
                font-weight: 300;
                text-decoration: none;
                padding: 10px 15px 10px 15px;
                border-bottom: 1px solid black;
            }
        }

        .markClose {
            .barOne {
                width: 35px;
                height: 5px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
                transform: translate(0, 11px) rotate(-45deg);
            }

            .barTwo {
                width: 35px;
                height: 5px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
                opacity: 0;
            }

            .barThree {
                width: 35px;
                height: 5px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
                transform: translate(0, -11px) rotate(45deg);
            }
        }

        .contentMenu {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;

            .btnLink {
                color: white;
                font-weight: 300;
                text-decoration: none;
                padding: 10px 15px 10px 15px;
                border-bottom: 1px solid black;
            }

        }

        .selectLangMb {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 10px;

            .btnLangMb {
                margin-left: 10px;
                color: var(--font-color);
                border-right: 1px solid #FFFFFF;
                padding-right: 10px;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .renderMenu {
        transition: all 0.5s ease-in-out;
        transform: translateX(0%);
        opacity: 1;
        position: fixed;
        z-index: 2;
        left: 0;
        top: 85px;
        width: 100%;
        height: 100vh;
        background-color: #7a5e1b;

        @media (min-width: 768px) {
            display: none;
        }

        .selectOptionsAccount {
            padding-left: 10px;
            padding-right: 10px;
            color: var(--font-color);
            display: flex;
            flex-direction: column;

            .btnAcc {
                color: white;
                font-weight: 300;
                text-decoration: none;
                padding: 10px 15px 10px 15px;
                border-bottom: 1px solid black;
            }
        }

        .markClose {
            .barOne {
                width: 35px;
                height: 5px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
                transform: translate(0, 11px) rotate(-45deg);
            }

            .barTwo {
                width: 35px;
                height: 5px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
                opacity: 0;
            }

            .barThree {
                width: 35px;
                height: 5px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
                transform: translate(0, -11px) rotate(45deg);
            }
        }

        .contentMenu {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;

            .btnLink {
                color: white;
                font-weight: 300;
                text-decoration: none;
                padding: 10px 15px 10px 15px;
                border-bottom: 1px solid black;
            }

        }

        .selectLangMb {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 10px;

            .btnLangMb {
                margin-left: 10px;
                color: var(--font-color);
                border-right: 1px solid #FFFFFF;
                padding-right: 10px;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}