.sectionFooter {
    background-color: black;

    .contentFooter {
        width: 100%;
        height: 100%;
        background: linear-gradient(#37302e, #111111);
        border-top-right-radius: 200px;
        padding: 1rem;

        @media (min-width: 1440px) {
            padding: 1rem 12rem 1rem 12rem;
        }

        .production {
            margin-top: 2rem;
            color: var(--font-color);
            font-size: 20px;
            font-weight: 500;
            padding-bottom: 2rem;
            border-bottom: 2px solid var(--secondary-color);

            .imgProduction {
                margin-top: 15px;
                display: flex;
                flex-direction: row;

                .iconLogo {
                    margin-right: 20px;
                    cursor: pointer;
                    width: 65px;
                    height: 65px;

                    @media (min-width: 768px) {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }

        .footerNavList {

            @media (min-width: 768px) {
                display: grid;
                grid-template-columns: 33% 33% 33%;
            }

            @media (min-width: 1440px) {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
            }

            .listContent {
                margin-top: 2rem;

                @media (min-width: 1440px) {
                    min-width: 250px;
                    max-width: 360px;
                }

                .headTitle {
                    color: var(--font-color);
                    font-size: 22px;
                    font-weight: 500;
                }

                .subTitle {
                    color: var(--font-color);
                    font-size: 18px;
                    font-weight: 500;
                }

                .childTitle {
                    color: var(--font-color);
                    font-size: 16px;
                    font-weight: 300;

                    a {
                        text-decoration: none;
                        color: white;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }

                .contactTitle {
                    color: var(--font-color);
                    font-size: 16px;
                    font-weight: 300;
                }

                .socialContent {
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: row;

                    @media (min-width: 1024px) {
                        margin-top: 2rem;
                    }

                    .socialList {
                        margin-right: 20px;
                        cursor: pointer;
                    }
                }
            }

            .social {
                display: flex;
                flex-direction: row;
                margin-top: 8px;

                @media (min-width: 768px) {
                    display: none;
                }

                .socialList {
                    margin-right: 15px;
                    cursor: pointer;
                }
            }
        }

        .socialLg {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }

            .socialLgList {
                display: flex;
                flex-direction: row;
                justify-content: end;
                padding-right: 1rem;
                padding-top: 10px;

                @media (min-width: 1024px) {
                    padding-right: 6rem;
                }

                .socialList {
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
        }

        .cop {
            font-weight: 200;
            margin-top: 3rem;
            color: #AD9C94;
            text-align: center;
        }
    }
}