.wrapper {
    background-color: #3a24f0;
    padding: 10px;
    width: 100%;
    /* Pay special attention here! */
    position: sticky;
    top: 0px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(50, 50, 50);
    background: linear-gradient(90deg,
            rgba(50, 50, 50, 1) 0%,
            rgba(8, 8, 8, 1) 49%,
            rgba(50, 50, 50, 1) 100%);

    .logoWrapper {
        width: 12em;
        height: auto;

        .logo {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}